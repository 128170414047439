html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: Quicksand;
  position: fixed;
}

#root {
  height: 100%;
  overflow-x: hidden;
}

@font-face {
  font-family: Quicksand;
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src: url(./fonts/Quicksand-Regular.ttf);
}

@font-face {
  font-family: Quicksand;
  font-style: normal;
  font-display: block;
  font-weight: 700;
  src: url(./fonts/Quicksand-SemiBold.ttf);
}

@media only screen and (min-width: 769px) {
  html {
    overflow: overlay !important;
  }

  ::-webkit-scrollbar {
    min-width: 16px;
    min-height: 16px;
  }

  ::-webkit-scrollbar-thumb {
    border: solid 6px transparent;
    border-radius: 9px;
    background-clip: content-box;
    background-color: #a9a9a964;
  }
}
